"use client";

import React, {
  createElement,
  Fragment,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { usePagination, useSearchBox } from "react-instantsearch";
import { autocomplete } from "@algolia/autocomplete-js";
import { createLocalStorageRecentSearchesPlugin } from "@algolia/autocomplete-plugin-recent-searches";
import { createRoot } from "react-dom/client";
import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import "@algolia/autocomplete-theme-classic";
import { querySuggestionsIndex } from "../../../search/searchClient";
import { debounce } from "@algolia/autocomplete-shared";
import { useRouter } from "next/navigation";
import { AutocompleteProps } from "@/search/types";
import classnames from "classnames";

/* Autocomplete is used in SearchBar.tsx, and you find its configuration here, like the plugins and the autocomplete instance.

Followed this guide to build this component: https://www.algolia.com/doc/ui-libraries/autocomplete/integrations/with-react-instantsearch/?client=Autocomplete.tsx */

export function Autocomplete({
  searchClient,
  className,
  ...autocompleteProps
}: AutocompleteProps) {
  const autocompleteContainer = useRef<HTMLDivElement>(null);
  const panelRootRef = useRef<ReturnType<typeof createRoot> | null>(null);
  const rootRef = useRef<
    Element | DocumentFragment | Document | ShadowRoot | null
  >(null);
  const router = useRouter();
  const { query, refine: setQuery } = useSearchBox();
  const { refine: setPage } = usePagination();

  const [instantSearchUiState, setInstantSearchUiState] = useState<{
    query: string;
  }>({ query });

  const debouncedSetInstantSearchUiState = debounce(
    setInstantSearchUiState,
    1000,
  );

  useEffect(() => {
    setQuery(instantSearchUiState.query);
    setPage(0);
  }, [instantSearchUiState]);

  const plugins = useMemo(() => {
    // First plugin, shows recent searches
    const recentSearches = createLocalStorageRecentSearchesPlugin({
      key: "instantsearch",
      limit: 3,
      transformSource({ source }) {
        return {
          ...source,
          onSelect({ item }) {
            setInstantSearchUiState({ query: item.label });
            router.push(`/search/${item.label}`); // Navigate to search page with the selected recent search, works both with mouse click and keyboard enter
          },
          getItems(params) {
            if (!params.state.query) {
              return [];
            }
            return source.getItems(params);
          },
        };
      },
    });

    // Second plugin, shows query suggestions
    const querySuggestions = createQuerySuggestionsPlugin({
      searchClient,
      indexName: querySuggestionsIndex, // Own Query Suggestions index (with multiple indexes as sources), since we want query suggestions from multiple indexes, both books and author index
      getSearchParams() {
        return recentSearches.data!.getAlgoliaSearchParams({
          hitsPerPage: 6,
        });
      },
      transformSource({ source }) {
        return {
          ...source,
          sourceId: "querySuggestionsPlugin",
          onSelect({ item }) {
            setInstantSearchUiState({ query: item.query });
          },
          getItems(params) {
            if (!params.state.query) {
              return [];
            }
            return source.getItems(params);
          },
        };
      },
    });

    return [recentSearches, querySuggestions];
  }, []);

  useEffect(() => {
    if (!autocompleteContainer.current) {
      return;
    }

    const autocompleteInstance = autocomplete({
      ...autocompleteProps,
      container: autocompleteContainer.current,
      initialState: { query },
      onReset() {
        setInstantSearchUiState({ query: "" });
      },
      onSubmit: ({ state }) => {
        setInstantSearchUiState({ query: state.query });
        state.query
          ? router.push(`/search/${state.query}`)
          : router.push("/search"); // Navigate to search page when writing in search field, search either by mouse click or keyboard enter
      },
      onStateChange({ prevState, state }) {
        if (prevState.query !== state.query) {
          debouncedSetInstantSearchUiState({
            query: state.query,
          });
        }
      },
      plugins,
      navigator: {
        navigate({ itemUrl }) {
          router.push(itemUrl); // Navigate to book product page with keyboard enter
        },
      },
      renderer: {
        createElement,
        Fragment,
        render(children, root) {
          if (!panelRootRef.current || rootRef.current !== root) {
            rootRef.current = root;
            panelRootRef.current?.unmount();
            panelRootRef.current = createRoot(root);
          }
          panelRootRef.current.render(children);
        },
      },
    });

    return () => autocompleteInstance.destroy();
  }, [plugins]);

  return (
    <div
      className={classnames("relative w-full", className)}
      ref={autocompleteContainer}
    />
  );
}
