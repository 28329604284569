"use client";

import { getAlgoliaResults } from "@algolia/autocomplete-js";
import { Autocomplete } from "./Autocomplete";
import { ArrowRight } from "@/assets";
import { slugify } from "@/utils/slugify";
import { AuthorHit, BookHit } from "@/search/types";
import { authorIndex, bookIndex, searchClient } from "@/search";
import Link from "next/link";
import Image from "next/image";

/* The SearchBar includes a custom Autocomplete component, which provides the search box for users.
This file configures the suggested results (e.g., books and authors) that appear in the dropdown. 
You can add sources (indexes), set up navigation to product pages, control the number of results, and customize their display.
For the autocomplete instance and plugins (e.g., recent searches, query suggestions), see Autocomplete.tsx.

Reference: https://www.algolia.com/doc/ui-libraries/autocomplete/integrations/with-react-instantsearch/?client=Autocomplete.tsx */

interface SearchSectionHeaderProps {
  title: string;
  query?: string;
  showAllLink?: boolean;
  link?: string;
}

interface SearchResultItemProps {
  imageSrc?: string;
  altText: string;
  link: string;
  highlightName: React.ReactNode;
  highlightDetails?: React.ReactNode;
  price?: string;
}

export const SearchBar = () => {
  return (
    <div className="relative flex items-center">
      <Autocomplete
        classNames={{
          panel: "z-50",
        }}
        className={
          "w-full rounded-[1.875rem] bg-white px-4 py-3 sm:bg-[var(--bg-primary)] sm:px-0 sm:py-0 [&_.aa-Form]:h-11 [&_.aa-Form]:rounded-[1.875rem] [&_.aa-Form]:border-none [&_.aa-Form]:bg-[#EFF0F2] [&_.aa-Form]:text-sm [&_.aa-Input]:placeholder-[#686B72] [&_.aa-SubmitIcon]:text-[#686B72]"
        }
        searchClient={searchClient}
        placeholder="Søk i alt fra Cappelen Damm"
        detachedMediaQuery="none"
        openOnFocus
        insights
        getSources={({ query }: { query: string }) => [
          {
            sourceId: "books",
            getItemUrl({ item }) {
              const bookHit: BookHit = item as BookHit;
              const slug = slugify([
                bookHit.name,
                bookHit.author,
                bookHit.isbn.toString(),
              ]);
              return `/boker/${slug}`;
            },
            getItems() {
              return getAlgoliaResults({
                searchClient: searchClient,
                queries: [
                  {
                    indexName: bookIndex,
                    params: {
                      query,
                      hitsPerPage: 3,
                    },
                  },
                ],
              });
            },
            templates: {
              header(params) {
                return (
                  <SearchSectionHeader
                    title="Bøker"
                    query={params?.state?.query}
                    showAllLink
                    link="/search"
                  />
                );
              },
              item({ item, components }) {
                const bookHit: BookHit = item as BookHit;
                const slug = slugify([
                  bookHit.name,
                  bookHit.author,
                  bookHit.isbn.toString(),
                ]);
                return (
                  <SearchResultItem
                    imageSrc={bookHit.firstImage}
                    altText="Bilde av boken"
                    link={`/boker/${slug}`}
                    highlightName={components.Highlight({
                      hit: bookHit,
                      attribute: ["name"],
                    })}
                    highlightDetails={components.Highlight({
                      hit: bookHit,
                      attribute: ["author"],
                    })}
                    price={bookHit.price}
                  />
                );
              },
            },
          },
          {
            sourceId: "authors",
            getItemUrl({ item }) {
              const authorHit: AuthorHit = item as AuthorHit;
              const slug = slugify([authorHit.fullName]);
              return `/forfatter/${slug}`;
            },
            getItems() {
              return getAlgoliaResults({
                searchClient: searchClient,
                queries: [
                  {
                    indexName: authorIndex,
                    params: {
                      query,
                      hitsPerPage: 3,
                    },
                  },
                ],
              });
            },
            templates: {
              header() {
                return <SearchSectionHeader title="Forfatter" />;
              },
              item({ item, components }) {
                const authorHit: AuthorHit = item as AuthorHit;
                const slug = slugify([authorHit.fullName]);
                return (
                  <SearchResultItem
                    imageSrc={authorHit.imageURL}
                    altText="Bilde av forfatteren"
                    link={`/forfatter/${slug}`}
                    highlightName={components.Highlight({
                      hit: authorHit,
                      attribute: ["fullName"],
                    })}
                  />
                );
              },
            },
          },
        ]}
      />
    </div>
  );
};

const SearchSectionHeader = ({
  title,
  query,
  showAllLink,
  link,
}: SearchSectionHeaderProps) => {
  return (
    <div className="pb-4">
      {query && (
        <p className="mt-6 border-b border-[#CACBCE] pb-2">
          Dette finner vi på “{query}”
        </p>
      )}
      <div className="flex">
        <span className="font-semibold">{title}</span>
        {showAllLink && link && (
          <Link className="ml-[auto] flex gap-2" href={link}>
            <span className="underline">Vis alle treff</span>
            <ArrowRight size={18} />
          </Link>
        )}
      </div>
    </div>
  );
};

const SearchResultItem = ({
  imageSrc,
  altText,
  link,
  highlightName,
  highlightDetails,
  price,
}: SearchResultItemProps) => {
  return (
    <Link
      tabIndex={0}
      role="button"
      style={{ cursor: "pointer" }}
      href={link}
      className="mb-4 flex cursor-pointer items-center gap-8 px-4 py-2"
    >
      <div className="relative flex h-[5.25rem] w-[5.25rem] flex-shrink-0 items-center justify-center rounded bg-[#F7F7F8]">
        {imageSrc && (
          <Image
            src={imageSrc}
            alt={altText}
            className="rounded object-cover"
            layout="fill"
            objectFit="contain"
          />
        )}
      </div>
      <div className="flex flex-col gap-3">
        <span className="h-5 truncate font-bold">{highlightName}</span>
        {highlightDetails && <span>{highlightDetails}</span>}
        {price && <span>{price},–</span>}
      </div>
    </Link>
  );
};
