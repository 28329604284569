"use client";
import classNames from "classnames";
import React, { useState } from "react";
import CMSLink from "@/cms/links/components/CMSLink";
import { ArrowRight, CaretLeft } from "@/assets";
import { LinkGroup } from "./headertypes";
import { Link } from "@/cms/links";

type LinkGroupDesktopHeaderProps = {
  linkGroup: LinkGroup;
};

const LinkGroupDesktopHeader = ({ linkGroup }: LinkGroupDesktopHeaderProps) => {
  const [layerHistory, setLayerHistory] = useState([linkGroup]);

  const addToLayerHistory = (pagePath: LinkGroup) => {
    setLayerHistory([...layerHistory, pagePath]);
  };
  const removeCurrentLayer = () => {
    const slicedLayerHistory = layerHistory.slice(0, -1);
    setLayerHistory(slicedLayerHistory);
  };

  const currentLayer = layerHistory[layerHistory.length - 1];

  return (
    <>
      {layerHistory && (
        <ul className="w-fil flex flex-col gap-3 rounded-xl border border-[#E3E3E5] p-6">
          <div className="flex items-center gap-2">
            {layerHistory.length > 1 && (
              <span
                className="flex h-[26px] cursor-pointer items-center gap-1 text-[#9A4234] underline"
                onClick={() => removeCurrentLayer()}
              >
                <CaretLeft />
              </span>
            )}
            <span className="h-[26px] cursor-pointer items-center gap-1 font-semibold text-[#9A4234] underline">
              <CMSLink
                data={{
                  ...currentLayer,
                  variant: "custom",
                }}
                className="flex gap-1"
                parentIndex={0}
                parentSection="header"
                itemIndex={0}
              />
            </span>
          </div>

          {currentLayer.linkGroup?.map(
            (link: Link | LinkGroup, index: number) => {
              if (link._type === "linkConfig") {
                return (
                  <li key={link._key}>
                    <CMSLink
                      data={{
                        ...link,
                        variant: "custom",
                      }}
                      className="flex gap-1 hover:underline"
                      parentIndex={0}
                      parentSection="header"
                      itemIndex={index}
                    />
                  </li>
                );
              }
              // Must be type LinkGroup to reach here
              return (
                <li
                  key={link._key}
                  onClick={() =>
                    addToLayerHistory(
                      layerHistory.length < 1
                        ? (linkGroup?.linkGroup?.[index] as LinkGroup)
                        : (currentLayer.linkGroup?.[index] as LinkGroup),
                    )
                  }
                >
                  <button
                    className={classNames("flex w-full gap-1 font-semibold")}
                  >
                    {link.title}
                    <span className="ml-auto">
                      <ArrowRight size={24} />
                    </span>
                  </button>
                </li>
              );
            },
          )}
        </ul>
      )}
    </>
  );
};
export default LinkGroupDesktopHeader;
