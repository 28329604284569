export const slugify = (value: string[]): string => {
  return value
    .filter(Boolean)
    .join("_")
    .replace(/\s/gi, "_")
    .replace(/æ/gi, "ae")
    .replace(/ø/gi, "o")
    .replace(/å/gi, "a")
    .replace(/\W/gi, "")
    .replace(/_/gi, "-")
    .replace(/--/gi, "-")
    .toLowerCase();
};
