"use client";
import {
  FC,
  createContext,
  useContext,
  useEffect,
  useId,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { List, X } from "@phosphor-icons/react/dist/ssr";
import { SanityImage } from "@/cms/images/types";
import FocusLock from "react-focus-lock";
import { SanityReference } from "@sanity/image-url/lib/types/types";
import { usePathname } from "next/navigation";
import { LinkBlock } from "../Footer/footertypes";

type HamburgerButtonProps = {
  className?: string;
  image?: SanityImage;
  linkBlock?: LinkBlock;
  moreInfo?: SanityReference[];
};

type HeaderMenu = { isOpen: boolean; level: number };

const MenuContext = createContext<{
  state: HeaderMenu;
  setState: Dispatch<SetStateAction<HeaderMenu>>;
}>({
  state: {
    isOpen: false,
    level: -1,
  },
  setState: () => undefined,
});

const useMenuContext = () => useContext(MenuContext);

export const MenuTop: FC<JSX.IntrinsicElements["button"]> = ({ children }) => {
  const { setState } = useMenuContext();
  return (
    <div className="mb-20 p-4">
      {children}
      <button
        className="absolute right-8 top-8 rounded-full bg-[#F7F7F8] p-4"
        onClick={() => setState({ isOpen: false, level: -1 })}
        aria-label="Lukk meny"
      >
        <X className="inline" size={24} />
      </button>
    </div>
  );
};

export const Overlay: FC<JSX.IntrinsicElements["button"]> = ({ onClick }) => (
  <button
    className="absolute left-0 top-0 z-[11] h-screen w-[100vw] bg-[#272525] opacity-25"
    onClick={onClick}
  ></button>
);

export const MenuButton: FC<
  JSX.IntrinsicElements["button"] & Partial<HeaderMenu>
> = ({ level, isOpen, children, ...rest }) => {
  const { setState } = useMenuContext();

  return (
    <button
      {...rest}
      onClick={() => {
        setState((prev) => ({
          ...prev,
          level: level ?? prev.level,
          isOpen: isOpen ?? prev.isOpen,
        }));
      }}
    >
      {children}
    </button>
  );
};

export const SubMenu: FC<
  JSX.IntrinsicElements["div"] & Pick<HeaderMenu, "level">
> = ({ level, children, ...rest }) => {
  const { state } = useMenuContext();
  if (state.level === level) return <div {...rest}>{children}</div>;
  return null;
};

export const MenuNavigation: FC<
  HamburgerButtonProps & JSX.IntrinsicElements["div"]
> = ({ className, children }) => {
  const id = useId();
  const pathname = usePathname();
  const [menuState, setMenuState] = useState<HeaderMenu>({
    isOpen: false,
    level: -1,
  });

  useEffect(() => {
    setMenuState({ isOpen: false, level: -1 });
  }, [pathname]);

  useEffect(() => {
    if (!menuState.isOpen) document.body.style.overflow = "auto";
  }, [menuState.isOpen]);

  return (
    <MenuContext.Provider value={{ state: menuState, setState: setMenuState }}>
      <button
        className="flex gap-2 sm:hidden"
        id={id}
        onClick={() => {
          setMenuState((prev) => {
            return { ...prev, isOpen: true };
          });
          document.body.style.overflow = "hidden";
        }}
        aria-label="Meny"
        data-testid="menu-button"
        aria-expanded={menuState.isOpen}
      >
        <List className="inline" size={28} />
      </button>
      {menuState.isOpen && (
        <FocusLock>
          <nav aria-labelledby={id} className={className}>
            <Overlay
              onClick={() => {
                setMenuState({ level: -1, isOpen: false });
                document.body.style.overflow = "auto";
              }}
            />
            {children}
          </nav>
        </FocusLock>
      )}
    </MenuContext.Provider>
  );
};
