"use client";

import { useIsEnabled } from "@sanity/preview-kit";
import { LiveQuery } from "next-sanity/preview/live-query";
import type { LiveQueryProps } from "@sanity/preview-kit/live-query";
import type { QueryParams } from "next-sanity";

export function LivePreview<QueryResult, U extends QueryParams = QueryParams>({
  children,
  ...rest
}: Omit<LiveQueryProps<QueryResult, U>, "enabled">) {
  const enabled = useIsEnabled();

  return (
    <LiveQuery {...rest} enabled={enabled}>
      {children}
    </LiveQuery>
  );
}
