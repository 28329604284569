"use client";
import classNames from "classnames";
import React from "react";
import { useState } from "react";
import CMSLink from "@/cms/links/components/CMSLink";
import { ArrowRight, X } from "@/assets";
import { LinkGroup } from "./headertypes";
import LinkGroupDesktopHeader from "./LinkGroupDesktopHeader";

type HeaderLinkBlockProps = {
  linkGroup: LinkGroup;
};

const HeaderLinkBlock = ({ linkGroup }: HeaderLinkBlockProps) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <ul className="h-[26px] gap-8 whitespace-nowrap sm:inline-flex">
      <li onClick={() => setOpen((isOpen) => !isOpen)}>
        <button
          className={classNames(
            "flex h-[26px] cursor-pointer flex-row-reverse gap-2 hover:border-b-2 hover:border-black",
          )}
        >
          {linkGroup.title}
        </button>
      </li>
      {isOpen && (
        <>
          <div
            className="fixed left-0 top-0 h-screen w-screen bg-black opacity-5"
            onClick={() => setOpen(false)}
          />
          <div className="absolute left-0 z-20 ml-[-12px] mt-[50px] h-[36.5rem] w-[1140px] rounded-xl bg-white p-4 shadow-2xl">
            <div className="flex h-[504px] w-full flex-col gap-3 overflow-y-auto overflow-x-hidden">
              <div className="flex">
                <CMSLink
                  data={{
                    ...linkGroup,
                    title: "",
                    variant: "custom",
                  }}
                  onClick={() => setOpen(false)}
                  parentIndex={0}
                  parentSection="header"
                  itemIndex={0}
                  className="flex px-4 py-3 font-semibold text-[#9A4234] underline"
                >
                  Gå til {linkGroup.title?.toLowerCase()}{" "}
                  <div className="pl-3">
                    <ArrowRight size={22} />
                  </div>
                </CMSLink>
                <button
                  className="ml-auto flex h-fit gap-3 px-6 py-3 text-lg text-[#9A4234]"
                  onClick={() => setOpen(false)}
                >
                  Lukk <X size={24} />
                </button>
              </div>
              <div
                className={classNames(
                  "grid gap-6",
                  linkGroup.linkGroup?.length && linkGroup.linkGroup.length <= 3
                    ? `grid-cols-${linkGroup.linkGroup.length}`
                    : "grid-cols-3",
                )}
              >
                {linkGroup.linkGroup?.map(
                  (linkGroup: LinkGroup, index: number) => {
                    if (linkGroup._type === "linkConfig") {
                      return (
                        <li
                          key={`${linkGroup._key}-link`}
                          className="w-fil flex rounded-xl border border-[#E3E3E5] p-4"
                        >
                          <CMSLink
                            data={{
                              ...linkGroup,
                              variant: "custom",
                              contentAlignment: "left",
                            }}
                            onClick={() => setOpen(false)}
                            parentIndex={0}
                            parentSection="header"
                            itemIndex={index}
                            className="flex font-semibold text-[#9A4234] underline"
                          />
                        </li>
                      );
                    }
                    return (
                      <LinkGroupDesktopHeader
                        key={`${linkGroup._key}-linkGroup`}
                        linkGroup={linkGroup}
                      />
                    );
                  },
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </ul>
  );
};
export default HeaderLinkBlock;
